import { template as template_e1057cdaf19b40fe88084c5e51fad99e } from "@ember/template-compiler";
const FKControlMenuContainer = template_e1057cdaf19b40fe88084c5e51fad99e(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
