import { template as template_dbed20868d6c4d42a5b221c4fadbbccd } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_dbed20868d6c4d42a5b221c4fadbbccd(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
