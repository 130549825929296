import { template as template_dd88dde0f81f4514ad0b1b74cf404438 } from "@ember/template-compiler";
const FKText = template_dd88dde0f81f4514ad0b1b74cf404438(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
